import Hamburger from './hamburger';

class Header {
	hamburger = null;
	isMobileMenuOpen = false;

	constructor($el) {
		this.$el = $el;
		this.$links = $el.find('.mobile-navigation .main-navigation__link');
		this.init();
	}

	init = () => {
		this.initHamburger();
		this.initObserver();
		this.$links.on('click', this.hamburger.close);
	};

	initObserver = () => {
		this.observer = window._Observer.create({
			target: window,
			onChangeY: this.onChangeY,
		});
	};

	initHamburger = () => {
		this.hamburger = new Hamburger(this.$el.find('[data-hamburger="el"]'));
	};
}

export default Header;
