class Highlight {
	constructor($el) {
		this.$el = $el;
		this.$linesTop = $el.find('[data-highlight="lines-top"]');
		this.$linesBottom = $el.find('[data-highlight="lines-bottom"]');
		this.$lines = $el.find('[data-highlight="line"]');

		this.init();
	}

	init = () => {
		// for each lines
		this.$lines.each((i, el) => {
			window._gsap.to(el, {
				scrollTrigger: {
					trigger: el,
					start: 'top 75%',
					scrub: true,
					// markers: true,
				},
				y: '50%',
				opacity: 1,
				duration: 1,
			});
		});
	};
}

export default Highlight;
