import '../css/app.scss';
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Observer from 'gsap/Observer';

/** Pages */
import Standard from './pages/standard';

/** Global Components */
import Header from './components/header';

$(() => {
	// init global components
	window._gsap = gsap;
	window._ScrollTrigger = ScrollTrigger;
	window._Observer = Observer;
	window._gsap.registerPlugin(ScrollTrigger);
	window._gsap.registerPlugin(Observer);

	window._header = new Header($('[data-header="el"]'));

	barba.use(barbaPrefetch);

	if ('scrollRestoration' in window.history) {
		window.history.scrollRestoration = 'manual';
	}

	// init barba
	barba.init({
		timeout: 30000,
		views: [Standard],
		prevent: ({ el }) => {
			return (
				el.href.startsWith('#') ||
				el.href === window.location.href ||
				(el.id && el.id.startsWith('m'))
			);
		},
	});
});
