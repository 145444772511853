import Highlight from '../components/highlight';
// import Lightbox from '../components/lightbox';

class Default {
	beforeEnter(data) {
		const scope = data.next.container;
		this.initHighlight(scope);
		this.initLightbox(scope);
	}

	afterEnter(data) {
		console.log('afterEnter', data);
		const scope = data.next.container;
		this.runGA();
		this.refreshScrollTriggers();

		setTimeout(() => {
			this.jumpToAnchor(scope);
		}, 250);
	}

	beforeLeave(data) {
		window._header.hamburger.close();
		// smooth scroll to top
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}

	afterLeave(data) {
		console.log('afterLeave');
		this.killScrollTriggers();
	}

	initHighlight = scope => {
		const $highlights = $(scope).find('[data-highlight="el"]');

		$highlights.each((i, el) => {
			new Highlight($(el));
		});
	};

	killScrollTriggers = () => {
		if (window._ScrollTrigger) {
			const allTrigger = window._ScrollTrigger.getAll();
			for (let i = 0; i < allTrigger.length; i++) {
				allTrigger[i].kill(true);
			}
		}
	};

	refreshScrollTriggers = () => {
		if (window._ScrollTrigger) {
			window._ScrollTrigger.refresh(true);
		}
	};

	runGA = () => {
		if (typeof gtag === 'function') {
			const path = window.location.href
				.replace(window.location.origin, '')
				.toLowerCase();
			gtag('js', new Date());
			gtag('config', 'G-KDQVRDK29T', {
				page_title: document.title,
				page_path: path,
			});
		}
	};

	jumpToAnchor = scope => {
		if (window.location.hash) {
			// Fragment exists
			let hash = window.location.hash.substring(1);

			let $target = $(scope).find(`#${hash}`);
			let headerHeight = $('header').outerHeight();

			$('html, body').animate(
				{
					scrollTop: $target.offset().top - headerHeight,
				},
				500
			);
		}
	};
}

export default Default;
