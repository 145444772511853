class Hamburger {
	isOpen = false;
	stateClasses = {
		open: 'navigation--open',
	};
	constructor($el) {
		this.$el = $el;
		this.$html = $('html');
		this.init();
	}

	init = () => {
		this.$el.on('click', this.toggle);
	};

	toggle = () => {
		if (this.isOpen) {
			this.close();
		} else {
			this.open();
		}
	};

	open = () => {
		this.$html.addClass(this.stateClasses.open);
		this.isOpen = true;
	};

	close = () => {
		this.$html.removeClass(this.stateClasses.open);
		this.isOpen = false;
	};
}

export default Hamburger;
